import { Fragment, useCallback, useEffect, useState } from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import TableRenderers from 'react-pivottable/TableRenderers';
import { aggregators, numberFormat, aggregatorTemplates } from 'react-pivottable/Utilities';
import 'react-pivottable/pivottable.css';
import DrawerComponent from '../DrawerComponent';
import useFetch from '../../hooks/useFetch';
import { BsSave2 } from 'react-icons/bs';
import fetcher from '../../api/fetcher';
import { Modal, Button, Form } from 'react-bootstrap';

export default function PivotTabella2({updateResearch, refreshDataResearch, savedResearches, pivotTableUIConfig, setPivotTableUIConfig, selectResearchFromDrawer, data, filtri}) {
  const [showModal, setShowModal] = useState(false); // Stato per la modale
  const [pendingSave, setPendingSave] = useState(null); // Stato per tenere traccia della configurazione in attesa di salvataggio
  const [newName, setNewName] = useState('');

  const hiddenFields = [
    'data',
    'film',
    'genere',
    'versione_film',
    'distribuzione',
    'distribuzione_contabile',
    'tipologia_film',
    'nazione',
    'cinema',
    'citta',
    'circuito',
    'provincia_cinema',
    'agenzia',
    'sala',
    'spettacolo'
  ];

  
  const handleConfirmSave = (e) => {
    e.preventDefault();
    const currentName = newName; // Leggi lo stato corrente
    savePivot(currentName);
    setShowModal(false); // Nascondi la modale dopo la conferma
  };
  const savePivot = useCallback(async (name) => {
    if (!pendingSave) return;
    
    let payload = {
      blob: JSON.stringify({pivot: pendingSave, search: filtri}),
      preferred: false,
      description: name,
      isenabled: true,
      type: 'analisi_film_pivot'
    };

    fetcher({
      url: `users/settings/research`,
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(({ data }) => {
      refreshDataResearch();
      setPendingSave(null); // Resetta lo stato dopo il salvataggio
    });
  }, [pendingSave, refreshDataResearch, filtri]);

  const deleteResearch = useCallback(
    async (item) => {
      /* setIsLoading(true); */
      fetcher({
        url: `users/settings/research/${item.id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(({ data }) => {
          refreshDataResearch();
        })
        .finally(() => {
          /* setLoading(false); */
        });
    },
    [refreshDataResearch]
  );

  const handleSaveClick = () => {
    const currentPivotTableUIConfig = {
      hiddenFromDragDrop: [...pivotTableUIConfig.hiddenFromDragDrop],
      rows: [...pivotTableUIConfig.rows],
      cols: [...pivotTableUIConfig.cols],
      vals: [...pivotTableUIConfig.vals]
    };
    setPendingSave(currentPivotTableUIConfig); // Imposta la configurazione attuale come pending
    setShowModal(true); // Mostra la modale
  };


  const handleCancelSave = () => {
    setPendingSave(null);
    setShowModal(false); // Nascondi la modale se l'utente annulla
  };

  // const selectResearchFromDrawer = (item) => {
  //   const data = JSON.parse(item.blob).pivot;
  //   setPivotTableUIConfig((c) => ({ ...c, ...data }));
  // };
  useEffect (()=>{
if (!showModal){
  setNewName("")
}
  },[showModal])

  return (
    <Fragment>
      {data.length > 0 && (
        <>
          <div className="col-12 d-flex justify-content-center align-items-center mb-5">
            {/* <div className="mx-2 my-2">
              <DrawerComponent
                isPreferred={true}
                data={data_preferiti?.research}
                preferredSave={(e) => saveToPreferredResearch(e)}
                selectResearchFromDrawer={selectResearchFromDrawer}
                deleteResearch={(e) => deleteResearch(e)}
              />
            </div> */}
            <div className="mx-2 my-2">
              <DrawerComponent
                isPivot={true}
                isPreferred={false}
                data={savedResearches}
                updateResearch={updateResearch}
                selectResearchFromDrawer={selectResearchFromDrawer}
                deleteResearch={(e) => deleteResearch(e)}
              />
            </div>
            <div className="mx-2 my-2">
              <button
                className={'btn btn-blue-cinetel mx-1'}
                title="Salva Ricerca"
                onClick={handleSaveClick} // Usa handleSaveClick al posto di savePivot direttamente
              >
                <BsSave2 className="position-relative me-1" style={{ marginBottom: '3px' }} />
                Salva Ricerca 
              </button>
            </div>
          </div>
          <PivotTableUI
            data={data}
            rows={pivotTableUIConfig.length > 0 ? pivotTableUIConfig[0].rows : ['distribuzione']}
            cols={pivotTableUIConfig.length > 0 ? pivotTableUIConfig[0].cols : ['agenzia']}
            vals={pivotTableUIConfig.length > 0 ? pivotTableUIConfig[0].vals : ['incasso']}
            aggregatorName="Integer Sum"
            hiddenFromAggregators={hiddenFields}
            aggregators={{
              ...aggregators,
              'Integer Sum': aggregatorTemplates.sum(
                numberFormat({ thousandsSep: '.', decimalSep: ',', digitsAfterDecimal: 0 })
              )
            }}
            enableColSort={true}
            rowOrder="value_z_to_a"
            onChange={(s) => setPivotTableUIConfig(s)}
            onRendererUpdate={(s) => setPivotTableUIConfig(s)}
            hiddenFromDragDrop={['incasso']}
            renderers={Object.assign({}, TableRenderers)}
            {...pivotTableUIConfig}
          />
        </>
      )}

      {/* Modale di conferma */}
      <Modal show={showModal} onHide={handleCancelSave}>
        <Modal.Header closeButton>
          <Modal.Title>Salva ricerca con relativa configurazione della tabella pivot</Modal.Title>
        </Modal.Header>
          <Form
          onSubmit={handleConfirmSave}>
        <Modal.Body>
            <Form.Group controlId="formNewName">
              <Form.Label>{'Inserisci il nome della ricerca'}</Form.Label>
              <Form.Control
              autoComplete='off'
                type="text"
                required
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
              />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelSave}>
            Annulla
          </Button>
          <Button type="submit" variant="primary" >
            Salva
          </Button>
        </Modal.Footer>
          </Form>
      </Modal>
    </Fragment>
  );
}
