import { Fragment, useCallback, useEffect } from 'react';
import { useState } from 'react';
import Loader from '../Loader';
import Form from 'react-bootstrap/Form';
import { printInteroConSeparatoriMigliaia } from '../../common/utils';
import ItemClassificaNew from '../classifiche/ItemClassificaNew';
import fetcher from '../../api/fetcher';
import HeaderClassificaNew from '../classifiche/HeaderClassificaNew';
import EsportaDati from '../EsportaDati';

const MAX_LEVELS = 3;

export default function ClassificaCittaNew({
	idperiodo,
	intervalloDate,
	filtro
}) {
	const [livello, setLivello] = useState(0);
	const [livelli, setLivelli] = useState();
	const [tipo, setTipo] = useState('incassi');
	const [data, setData] = useState();

	const queryStr = `?from=${intervalloDate[0]}&to=${intervalloDate[1]}${intervalloDate[2] ? '&primaprogram=1' : ''
		}&sortby=${tipo}`;


	useEffect(() => {
		if (idperiodo || filtro?.id === 0) {
			setLivello(0);
			setLivelli([
				{
					url: `/classifiche/${idperiodo ?? 0}/citta${idperiodo ? '?sortby=' + tipo : queryStr}`,
					header: 'Seleziona una città'
				}
			]);
		}
	}, [idperiodo, filtro, intervalloDate]);

	const aumentaLivello = useCallback(
		(id, header = null) => {
			let copia_livelli = [...livelli];
			if (copia_livelli.length < MAX_LEVELS) copia_livelli.push({});
			switch (livello) {
				case 0:
					copia_livelli[1].url = `/classifiche/${idperiodo ?? 0}/citta/${id}/cinema${idperiodo ? '?sortby=' + tipo : queryStr}`;
					copia_livelli[1].header = header;
					break;
				case 1:
					copia_livelli[2].url = `/classifiche/${idperiodo ?? 0}/cinema/${id}/films${idperiodo ? '?sortby=' + tipo : queryStr}`;
					copia_livelli[2].header = header;
					break;
				default:
					break;
			}
			setLivelli(copia_livelli);
			setLivello(liv => liv + 1);
		},
		[livello, livelli]
	);
	/*
		useEffect(() => {
			if (livello < 1) {
				setTipo(val =>
					['incassi', 'pesoinc'].includes(val) ? 'pesoinc' : 'pesopre'
				);
			} else {
			setTipo(val =>
			  ['incassi', 'pesoinc'].includes(val) ? 'incassi' : 'presenze'
			);
		  }
		}, [livello]);
	*/
	useEffect(() => {
		if (livelli) {
			const url = `${livelli[livello].url}`;

			setData();
			if (url !== '' && (idperiodo || filtro?.id === 0)) {
				let doUpdate = true;
				fetcher(url)
					.then(result => {
						if (doUpdate) {
							setData(result.data.data);
						}
					})
					.catch(error => {
						if (doUpdate) console.warn('erroreeeeeeee');
					});
				return () => (doUpdate = false);
			}
		}
	}, [livelli, livello, tipo, queryStr, idperiodo, filtro]);

	if (!data)
		return <Loader messaggio="Caricamento della classifica in corso..." />;

	if (data?.length === 0) return null;

	const totale = data.reduce(
		(partialSum, a) => (a['id'] === 0 ? partialSum : partialSum + a[tipo]),
		0
	);
	data.sort((a, b) => b[tipo] - a[tipo]);

	const classifica = data.map((c, idx) => {
		const percentuale = (c[tipo] / totale) * 100;
		return (
			<ItemClassificaNew
				key={c.id}
				id={c.id}
				posizione={idx + 1}
				titolo={`${c.descrizione} ${!!c.sale
					? '(' + c.sale + ' ' + (c.sale > 1 ? 'sale' : 'sala') + ')'
					: ''
					}`}
				sottotitolo={
					c.sottotitolo
						? c.sottotitolo
							.toLowerCase()
							.replace(/\b[a-z]/g, x => x.toUpperCase())
						: null
				}
				valore={
					['pesoinc', 'incassi'].includes(tipo)
						? '€ ' + printInteroConSeparatoriMigliaia(c.incassi)
						: printInteroConSeparatoriMigliaia(c.presenze)
				}
				percentuale={percentuale}
				link={c?.link}
				click={
					livello < MAX_LEVELS - 1 && c.id !== 0
						? () => aumentaLivello(c.id, c.descrizione)
						: null
				}
			/>
		);
	});

	return (
		<div className="my-5">
			<h3 className="text-center mb-2">Classifica Città</h3>
			<div className="d-flex justify-content-center mb-3">
				<Form.Check
					type="switch"
					checked={tipo === 'incassi'}
					onChange={() =>
						setTipo(val => (val === 'incassi' ? 'presenze' : 'incassi'))
					}
					label={tipo === 'incassi' ? 'Mostra presenze' : 'Mostra incassi'}
				/>
			</div>
			<div
				className="scollabile"
				style={{
					boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
					borderRadius: '6px',
					maxHeight: '30.6rem',
					minHeight: '30.6rem',
					overflowY: 'auto'
				}}
			>
				<HeaderClassificaNew
					testo={livelli[livello]?.header}
					click={
						livello < livelli.length && livello > 0
							? () => setLivello(l => l - 1)
							: null
					}
				/>
				<div>
					{data?.length > 0 ? (
						<Fragment>{classifica}</Fragment>
					) : (
						<Loader messaggio="Caricamento in corso..." />
					)}
				</div>
			</div>
			{data?.length > 0 && <EsportaDati url={livelli[livello].url} />}
		</div>
	);
}
