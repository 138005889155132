import React, { useEffect, useState } from 'react';
import Filtro from './Filtro';
import useFetch from '../../hooks/useFetch';
import { MultiSelect } from 'primereact/multiselect';
import MultiselectMoreFileds from '../film/MultiselectMoreFileds';
import { Col, Row } from 'react-bootstrap';

export default function FiltroFilm({
  selectAll,
  onChange,
  maxHeight,
  showRicerca,
  disabled = false,
  mode = 'table',
  titoli = null,
  filmSelezionati, 
  selectPrefferd
}) {
  const { data, error } = useFetch('liste/film', 'GET', null, !disabled);
  const [films, setFilms] = useState(null);
   

  useEffect(() => {
    if (!!data) {
      setFilms(
        data.map((f) => {
          return {
            valore: f.valore,
            regia: f.regia,
            keywords: f.keywords,
            attori: f.attori,
            descrizione: `${f.titolo.replace(`(${f.titolo_originale ?? ''})`, '')}`,
            dettaglio: `${f.titolo_originale ?? 'TITOLO ORIGINALE ASSENTE'} (${
              f.prima_program
            }) incasso totale: ${f.incasso} euro`
          };
        })
      );
    }
  }, [data]);

  return mode === 'table' ? (
    <Filtro
      titolo="film"
      dati={films}
      errore={error?.message}
      onChange={onChange}
      hasRecenti={false}
      showRicerca={showRicerca}
      selectAll={selectAll}
      maxHeight={maxHeight}
      itemsSelected={filmSelezionati}
      isResearchSelected={selectPrefferd}
      showFilmSearch={true}
    />
  ) : mode === 'multiselect' ? (
    <MultiSelect
      value={titoli}
      options={films}
      onChange={(e) => onChange(e.value)}
      optionLabel="descrizione"
      optionValue="valore"
      disabled={disabled}
      virtualScrollerOptions={{ itemSize: 40, scrollHeight: '29rem' }}
      filter
      placeholder="Seleziona una o più titoli"
      display="chip"
      className="w-100"
    />
  ) : (
    <>
      <Row className="align-items-center">
        <Col xs={12} md={8}>
          <MultiSelect
            value={titoli}
            options={films}
            onChange={(e) =>onChange(e.value)}
            optionLabel="descrizione"
            optionValue="valore"
            disabled={disabled}
            virtualScrollerOptions={{ itemSize: 40, scrollHeight: '29rem' }}
            filter
            placeholder="Seleziona una o più titoli"
            display="chip"
            className="w-100"
          />
        </Col>
        <Col xs={12} md={4}>
          <MultiselectMoreFileds
            value={titoli}
            options={films}
            disabled={disabled}
            onChange={onChange}
            optionLabel={'descrizione'}
            optionValue={'valore'}
            isAnalisi={true}
          />
        </Col>
      </Row>
    </>
  );
}
