import React, { useState } from 'react';
import { Offcanvas, Button, ListGroup, Row, Col, Modal, Form } from 'react-bootstrap';
import { BsList, BsStarFill, BsPencil, BsBoxArrowInUpRight, BsXCircle, BsCloudCheck } from 'react-icons/bs';
import moment from 'moment';

function DrawerComponent({
  isPreferred,
  data,
  updateResearch,
  selectResearchFromDrawer,
  deleteResearch,
  isPivot = false,
  isDettaglioFilm = false,
}) {
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newName, setNewName] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleRenameModalClose = () => setShowRenameModal(false);

  const handleDeleteClick = (item) => {
    setSelectedItem(item);
    setShowDeleteModal(true);
  };

  const handleRenameClick = (item) => {
    setSelectedItem(item);
    setNewName(item.description);
    setShowRenameModal(true);
  };

  const handleSelectClick = (item) => {
    selectResearchFromDrawer(item);
      setShow(false);
  };

  const handleDeleteConfirm = () => {
    deleteResearch(selectedItem);
    handleDeleteModalClose();
  };

  const handleRenameConfirm = (e, description) => {
    e.preventDefault();
    handleRenameModalClose();
    updateResearch({ ...selectedItem, description: description });
    // if (!isPreferred) {
    //   setShow(false);
    // }
  };

  return (
    <>
      <a
        className={isPreferred ? 'btn btn-danger mx-1' : 'btn btn-blue-cinetel mx-1'}
        title={'Ricerche Salvate'}
        onClick={handleShow}>
       
          <BsCloudCheck className="position-relative me-1" style={{ marginBottom: '3px' }} />

        {isPreferred ? 'Ricerche Preferite' : isPivot ? 'Ricerche Salvate' : 'Ricerche Salvate'}
      </a>

      <Offcanvas show={show} onHide={handleClose} placement="start" style={{ width: '60%' }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> {isPreferred ? 'Ricerche Preferite' : isPivot ? 'Ricerche Salvate' : 'Ricerche Salvate'}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup>
            {data?.map((item, index) => (
              <ListGroup.Item key={index}>
                <Row className="align-items-center">
                  <Col>
                        {item.description}
                  </Col>
                  <Col className="text-end align-items-center">
                    <Button
                      variant="danger"
                      className="ms-2"
                      size="sm"
                      onClick={() => handleDeleteClick(item)}>
                      <BsXCircle
                        className="position-relative me-1"
                        style={{ marginBottom: '3px' }}
                      />
                      Elimina
                    </Button>

                    
                      <Button
                        variant="warning"
                        className="ms-2"
                        size="sm"
                        onClick={() => handleRenameClick(item)}>
                        <BsPencil
                          className="position-relative me-1"
                          style={{ marginBottom: '3px' }}
                        />
                        Rinomina
                      </Button>
            
                    <Button
                      variant="secondary"
                      size="sm"
                      className="ms-2"
                      onClick={() => handleSelectClick(item)}>
                      <BsBoxArrowInUpRight
                        className="position-relative me-1"
                        style={{ marginBottom: '3px' }}
                      />
                      Utilizza
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={showDeleteModal} onHide={handleDeleteModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma Cancellazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sei sicuro di voler cancellare questo elemento?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModalClose}>
            Annulla
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Cancella
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showRenameModal} onHide={handleRenameModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{'Rinomina Elemento'}</Modal.Title>
        </Modal.Header>
          <Form
           onSubmit={(e) => handleRenameConfirm(e, newName)}
          >
        <Modal.Body>
            <Form.Group controlId="formNewName">
              <Form.Label>{'Modifica Nome Ricerca'}</Form.Label>
              <Form.Control
              autoComplete='off'
                type="text"
                required
                value={newName || ''}
                onChange={(e) => setNewName(e.target.value)}
              />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRenameModalClose}>
            Annulla
          </Button>
          <Button type="submit" variant="primary">
            Salva
          </Button>
        </Modal.Footer>
          </Form>
      </Modal>
    </>
  );
}

export default DrawerComponent;
